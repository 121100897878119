import React, { ReactElement } from "react";

import { MarkdownType } from "../interfaces";
import Markdown from "./Markdown";

interface ServiceInfoProps {
  content: MarkdownType;
}

export default function ServiceInfo({ content }: ServiceInfoProps): ReactElement {
  return (
    <div className="text-md md:text-lg">
      <span className="text-sm font-semibold text-navy-500 md:mb-10 md:text-lg">How it works</span>
      <Markdown markdown={content} />
    </div>
  );
}
