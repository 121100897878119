import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import clientConfigReducer from "./clientConfigSlice";
import { loadState, saveState } from "./sessionStorage";
import syncSessionReducer from "./syncSessionSlice";
import userReducer from "./userSlice";

export const store = configureStore({
  preloadedState: loadState(),
  reducer: {
    auth: authReducer,
    clientConfig: clientConfigReducer,
    sync: syncSessionReducer,
    user: userReducer,
  },
});

store.subscribe(() => {
  saveState(store.getState());
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
