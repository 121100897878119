import { useCallback, useState } from "react";

/**
 * useAsyncError
 *
 * This hook provides a function to throw errors in asynchronous contexts
 * in a way that can be caught by React error boundaries.
 *
 * It uses useState to schedule a re-render, during which the error is thrown.
 * This ensures the error is thrown during React's render phase, allowing
 * error boundaries to catch it.
 *
 * @returns A function that can be called with an error to be thrown
 *          during the next render cycle.
 *
 * @example
 * const throwError = useAsyncError();
 *
 * try {
 *   // Some async operation
 * } catch (error) {
 *   throwError(error);
 * }
 */
export default function useAsyncError() {
  const [, setError] = useState();
  return useCallback((error: unknown) => {
    setError(() => {
      throw error instanceof Error ? error : new Error(String(error));
    });
  }, []);
}
