import React, { useEffect, useRef, useState } from "react";

import { RequestError } from "../api/apiHandler";
import { IdentifyRequest, getServiceLines, identify } from "../api/connectApi";
import { Button } from "../components/Buttons/Button";
import ContentHeader from "../components/ContentHeader";
import RequestErrorMessage from "../components/Exception/RequestErrorMessage";
import { FormTextField } from "../components/FormTextField";
import useAsyncError from "../hooks/useAsyncError";
import useStateNavigate from "../hooks/useStateNavigate";
import { ServiceLine } from "../interfaces";
import { getAuthenticationParams, setAuthenticationParams } from "../store/authSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getUserParams, setMissingFields, setProfile } from "../store/userSlice";
import { parseFormData } from "../utils";
import AuthenticatedPageTemplate from "./template/AuthenticatedPageTemplate";
import ContentBody from "./template/ContentBody";
import { ContentFooter } from "./template/ContentFooter";

export default function Auth(): React.ReactElement {
  const [requestError, setRequestError] = useState<RequestError | null>(null);
  const asyncThrow = useAsyncError();
  const dispatch = useAppDispatch();
  const { navigateNext } = useStateNavigate();
  const authenticationParams = useAppSelector(getAuthenticationParams);
  const { profile } = useAppSelector(getUserParams);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [canNavigate, setCanNavigate] = useState<boolean>(false);

  useEffect(() => {
    if (canNavigate) navigateNext();
  }, [navigateNext, canNavigate, profile.verified]);

  const onInput = (ev: React.FormEvent<HTMLFormElement>) => {
    setIsFormValid(ev.currentTarget.checkValidity());
  };

  const onSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    setRequestError(null);
    setIsFormLoading(true);

    const data = parseFormData(new FormData(ev.currentTarget));
    const userName = data.fullName;

    try {
      const resp = await identify({ userName } as IdentifyRequest);
      if (resp.result.success) {
        let serviceLines = [] as Array<ServiceLine>;
        if (resp.payload.isAuthenticated) {
          const serviceLinesResp = await getServiceLines({ isHealthy: true });
          serviceLines = serviceLinesResp.payload.serviceLines;
        }
        dispatch(setMissingFields([]));
        dispatch(
          setProfile({
            ...profile,
            verified: resp.payload.isVerified,
            authorized: resp.payload.isAuthorized,
            serviceLines: serviceLines,
          }),
        );
        dispatch(
          setAuthenticationParams({
            ...authenticationParams,
            emailChallenged: true,
            emailAuthenticated: resp.payload.isAuthenticated,
          }),
        );
        setCanNavigate(true);
      }
    } catch (e) {
      if (e instanceof RequestError) {
        setRequestError(e);
      } else {
        asyncThrow(e);
      }
    } finally {
      setIsFormLoading(false);
    }
  };

  const formRef = useRef<HTMLFormElement>(null);

  return (
    <AuthenticatedPageTemplate>
      <ContentBody>
        <ContentHeader title="Get started" />
        <div className="mt-4 md:mt-10">
          <p className="text-md font-light md:text-lg">
            Please verify the below information. This information is used to create your business&apos;s profile and
            prevent fraud.
          </p>
        </div>
        <form
          ref={formRef}
          className="mt-4 flex flex-col gap-y-6 md:mb-4 md:mt-10 md:gap-y-8"
          onInput={(ev) => void onInput(ev)}
          onSubmit={(ev) => void onSubmit(ev)}
        >
          <div className="flex-1">
            <FormTextField
              label="Full name"
              name="fullName"
              pattern="^[a-zA-Z ]+$"
              required
              fullWidth
            />
          </div>
          <button
            type="submit"
            className="hidden"
          />
        </form>
      </ContentBody>
      <ContentFooter>
        <Button
          type="submit"
          label="Continue"
          disabled={!isFormValid || isFormLoading}
          primary
          fullWidth
          loading={isFormLoading}
          onClick={() => formRef?.current?.requestSubmit()}
        />
        {requestError && <RequestErrorMessage />}
      </ContentFooter>
    </AuthenticatedPageTemplate>
  );
}
