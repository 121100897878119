enum WebSocketEvents {
  Open = "open",
  Close = "close",
  Error = "error",
  Message = "message",
  Retry = "retry",
  RetryAttemptsExhausted = "retryAttemptsExhausted",
}

enum WebSocketCloseStatus {
  NormalClosure = 1000,
  GoingAway = 1001,
  ProtocolError = 1002,
  UnsupportedData = 1003,
  Reserved = 1004,
  NoStatusReceived = 1005,
  AbnormalClosure = 1006,
  InvalidPayloadData = 1007,
  PolicyViolation = 1008,
  MessageTooBig = 1009,
  MissingExtensions = 1010,
  InternalError = 1011,
  TLSHandshakeFailure = 1015,
}

interface WebSocketEventMap {
  open: Event;
  close: CloseEvent;
  error: Event;
  message: MessageEvent;
  retry: CustomEvent<RetryEvent>;
  retryAttemptsExhausted: CustomEvent<RetryAttemptsExhaustedEvent>;
}

type WebSocketEventListeners = {
  open: EventListener<WebSocketEvents.Open>[];
  close: EventListener<WebSocketEvents.Close>[];
  error: EventListener<WebSocketEvents.Error>[];
  message: EventListener<WebSocketEvents.Message>[];
  retry: EventListener<WebSocketEvents.Retry>[];
  retryAttemptsExhausted: EventListener<WebSocketEvents.RetryAttemptsExhausted>[];
};

type EventListener<T extends WebSocketEvents> = (instance: SimpleWebSocket, ev: WebSocketEventMap[T]) => void;

export interface RetryEvent {
  retries: number;
}

export interface RetryAttemptsExhaustedEvent {
  retries: number;
}

class Buffer<T> {
  /**
   * A buffer for websocket messages.
   * This is used to store messages that are sent when the websocket is not in a healthy open state.
   * When the websocket is opened, the messages are sent in the order they were received (FIFO).
   */
  private _buffer: T[] = [];

  public write(message: T): void {
    this._buffer.push(message);
  }

  public read(): T | null {
    if (!this.isEmpty) return this._buffer.shift() as T;
    return null;
  }

  public get isEmpty(): boolean {
    return this._buffer.length === 0;
  }
}

type WebsocketSerializationType = string | Blob | ArrayBuffer | ArrayBufferView;

type WebSocketBuffer = Buffer<WebsocketSerializationType>;

class ConnectionError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ConnectionError";
  }
}

/**
 * @class SimpleWebSocket
 * @description A wrapper around the native WebSocket API.
 * @param {string} url The url to connect to.
 * @param {number} reconnectTimeout The time in milliseconds to wait before attempting to reconnect.
 * @param {number} maxRetries The maximum number of times to attempt to reconnect.
 */
class SimpleWebSocket {
  private readonly _url: string;
  private _websocket: WebSocket | null = null;
  private readonly _eventListeners: WebSocketEventListeners = {
    open: [],
    close: [],
    error: [],
    message: [],
    retry: [],
    retryAttemptsExhausted: [],
  };
  private _outBuffer: WebSocketBuffer;
  private _inBuffer: WebSocketBuffer;
  private _incomingMessageResolver?: (value: void | PromiseLike<void>) => void;
  private _closedByClient = false;
  private _reconnectTimeout = 1000;
  private _retries = 0;
  private _maxRetries = 10;

  constructor(url: string, reconnectTimeout = 1000, maxRetries = 10) {
    this._url = url;
    this._reconnectTimeout = reconnectTimeout;
    this._maxRetries = maxRetries;
    this._outBuffer = new Buffer() as WebSocketBuffer;
    this._inBuffer = new Buffer() as WebSocketBuffer;
  }

  public get nativeWebSocket(): WebSocket | null {
    return this._websocket;
  }

  public get isConnected(): boolean {
    return this.nativeWebSocket?.readyState === WebSocket.OPEN;
  }

  public async connect(): Promise<void> {
    this._websocket = new WebSocket(this._url);
    this._websocket.addEventListener(WebSocketEvents.Open, this._handleOpenEvent);
    this._websocket.addEventListener(WebSocketEvents.Close, this._handleCloseEvent);
    this._websocket.addEventListener(WebSocketEvents.Error, this._handleErrorEvent);
    this._websocket.addEventListener(WebSocketEvents.Message, this._handleMessageEvent);

    return new Promise<void>((resolve, reject) => {
      if (!this._websocket) return;
      this._websocket.addEventListener(WebSocketEvents.Open, (ev) => {
        resolve();
      });
      this._websocket.addEventListener(WebSocketEvents.Error, (ev) => {
        reject(new ConnectionError("unable to establish connection"));
      });
    });
  }

  private _reconnect(): void {
    if (this._retries >= this._maxRetries) {
      this._dispatchEvent(
        WebSocketEvents.RetryAttemptsExhausted,
        new CustomEvent<RetryAttemptsExhaustedEvent>(WebSocketEvents.RetryAttemptsExhausted, {
          detail: {
            retries: this._retries,
          },
        }),
      );
      return;
    }

    setTimeout(() => {
      void (async () => {
        this._dispatchEvent(
          WebSocketEvents.Retry,
          new CustomEvent<RetryEvent>(WebSocketEvents.Retry, {
            detail: {
              retries: this._retries++,
            },
          }),
        );

        try {
          await this.connect();
        } catch (err) {
          if (!(err instanceof ConnectionError)) throw err;
        }
      })();
    }, this._reconnectTimeout);
  }

  public send<T extends WebsocketSerializationType>(data: T): void {
    if (!this._websocket) throw new ConnectionError("websocket is not initialized");
    if (this._closedByClient) return;

    if (!this.isConnected) this._outBuffer?.write(data);
    else this._websocket.send(data);
  }

  public async *receive<T extends WebsocketSerializationType>(): AsyncGenerator<T> {
    while (true) {
      await new Promise<void>((resolve) => {
        !this._inBuffer.isEmpty ? resolve() : (this._incomingMessageResolver = resolve);
      });
      yield this._inBuffer.read() as T;
    }
  }

  public close(code?: WebSocketCloseStatus, reason?: string): void {
    this._closedByClient = true;
    if (this.isConnected) this._websocket?.close(code?.valueOf(), reason);
  }

  public addEventListener<T extends WebSocketEvents>(
    type: T,
    listener: (instance: SimpleWebSocket, ev: WebSocketEventMap[T]) => void,
  ): void {
    const eventListeners = this._eventListeners[type] as EventListener<T>[];
    eventListeners.push(listener as EventListener<T>);
  }

  public removeEventListener<T extends WebSocketEvents>(
    type: T,
    listener: (instance: SimpleWebSocket, ev: WebSocketEventMap[T]) => void,
  ): void {
    const eventListeners = this._eventListeners[type] as EventListener<T>[];
    const index = eventListeners.indexOf(listener as EventListener<T>);
    if (index > -1) eventListeners.splice(index, 1);
  }

  private _handleOpenEvent = (ev: Event) => this._handleEvent(WebSocketEvents.Open, ev);

  private _handleCloseEvent = (ev: CloseEvent) => this._handleEvent(WebSocketEvents.Close, ev);

  private _handleErrorEvent = (ev: Event) => this._handleEvent(WebSocketEvents.Error, ev);

  private _handleMessageEvent = (ev: MessageEvent) => this._handleEvent(WebSocketEvents.Message, ev);

  private _handleEvent<T extends WebSocketEvents>(type: T, ev: WebSocketEventMap[T]) {
    switch (type) {
      case WebSocketEvents.Open:
        this._retries = 0;
        while (!this._outBuffer.isEmpty) {
          const m = this._outBuffer.read();
          if (m !== null) this._websocket?.send(m);
        }
        break;
      case WebSocketEvents.Message:
        this._inBuffer.write((ev as MessageEvent).data as WebsocketSerializationType);
        if (this._incomingMessageResolver) this._incomingMessageResolver();
        break;
      case WebSocketEvents.Close:
        if (!this._closedByClient) this._reconnect();
        break;
    }
    this._dispatchEvent<T>(type, ev);
  }

  private _dispatchEvent<T extends WebSocketEvents>(type: T, ev: WebSocketEventMap[T]) {
    const listeners = this._eventListeners[type] as EventListener<T>[];
    for (const l of listeners) l(this, ev);
  }
}

export { SimpleWebSocket, WebSocketEvents, ConnectionError, Buffer, WebSocketCloseStatus, type WebSocketBuffer };
