import React from "react";

interface BasisLogoProps {
  size?: "sm" | "md" | "lg";
  link?: boolean;
}

function BasisLogo({ size = "md", link = true }: BasisLogoProps) {
  const sizeToHeight: { [key: string]: number } = {
    sm: 3,
    md: 4,
    lg: 6,
  };

  const img = (
    <img
      src="/assets/basis/basis_logo.svg"
      alt=""
      className={`h-${sizeToHeight[size]} w-auto`}
    />
  );

  return link ? (
    <a
      href="https://usebasis.co"
      target="_blank"
      rel="noopener noreferrer"
    >
      {img}
    </a>
  ) : (
    img
  );
}

export default BasisLogo;
