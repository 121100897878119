import { StatusCodes } from "http-status-codes";
import React, { useEffect, useRef, useState } from "react";

import { ClientErrorCodeMinor, RequestError } from "../api/apiHandler";
import { VerifyRequest, getServiceLines, resend, verify } from "../api/connectApi";
import { Button } from "../components/Buttons/Button";
import GoBackButton from "../components/Buttons/GoBackButton";
import ContentHeader from "../components/ContentHeader";
import { PasscodeInput } from "../components/PasscodeInput";
import { defaultErrorMessage } from "../constants/constants";
import { useMobileHeaderBar } from "../contexts/MobileHeaderBarContext";
import useAsyncError from "../hooks/useAsyncError";
import useStateNavigate from "../hooks/useStateNavigate";
import { getAuthenticationParams, setAuthenticationParams } from "../store/authSlice";
import { getClientConfig } from "../store/clientConfigSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { getUserParams, setProfile } from "../store/userSlice";
import { parseFormData } from "../utils";
import AuthenticatedPageTemplate from "./template/AuthenticatedPageTemplate";
import ContentBody from "./template/ContentBody";
import { ContentFooter } from "./template/ContentFooter";

export default function VerifyEmail() {
  const asyncThrow = useAsyncError();
  const { navigateNext, navigateBack } = useStateNavigate();
  const { profile } = useAppSelector(getUserParams);
  const { customerShortName } = useAppSelector(getClientConfig);
  const authenticationParams = useAppSelector(getAuthenticationParams);
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [mfaInvalidated, setMfaInvalidated] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const [canNavigate, setCanNavigate] = useState<boolean>(false);
  const { setGoBackControl } = useMobileHeaderBar();

  useEffect(() => {
    if (canNavigate) navigateNext();
  }, [navigateNext, canNavigate, authenticationParams.emailChallenged, profile.verified]);

  function handleAuthRequestError(e: RequestError): void {
    if (e.status === StatusCodes.UNAUTHORIZED) {
      if (e.minor === ClientErrorCodeMinor.PayloadInvalid) {
        setErrorMessage("Incorrect Code. Please try again.");
      } else if (e.minor === ClientErrorCodeMinor.ChallengeExpired) {
        setErrorMessage("Challenge Expired. Please request a new code.");
      } else {
        setErrorMessage("An error occurred. Please try again.");
      }
    } else if (e.status === StatusCodes.FORBIDDEN) {
      setMfaInvalidated(true);
      setErrorMessage(`User Session Invalidated. Please return to ${customerShortName} and try connecting again.`);
    } else {
      setErrorMessage(defaultErrorMessage);
    }
  }

  const onInput = (ev: React.FormEvent<HTMLFormElement>) => {
    setIsFormValid(ev.currentTarget.checkValidity());
  };

  async function onSubmit(ev: React.FormEvent<HTMLFormElement>): Promise<void> {
    ev.preventDefault();
    setErrorMessage(null);
    setIsFormLoading(true);
    const data = parseFormData(new FormData(ev.currentTarget));

    const verifyReq: VerifyRequest = {
      passcode: data.code.toString(),
    };
    setErrorMessage(null);

    try {
      const resp = await verify(verifyReq);
      const serviceLinesResp = await getServiceLines({ isHealthy: true });
      dispatch(
        setProfile({
          ...profile,
          verified: true,
          authorized: resp.payload.isAuthorized,
          serviceLines: serviceLinesResp.payload.serviceLines,
        }),
      );
      if (resp.result.success) {
        dispatch(setAuthenticationParams({ ...authenticationParams, emailAuthenticated: true }));
        setCanNavigate(true);
      }
    } catch (e) {
      if (e instanceof RequestError) {
        handleAuthRequestError(e);
      } else {
        asyncThrow(e);
      }
    } finally {
      setIsFormLoading(false);
    }
  }

  const resendEmail = async () => {
    setIsFormLoading(true);
    try {
      await resend();
    } catch (e) {
      if (e instanceof RequestError) {
        handleAuthRequestError(e);
      } else {
        asyncThrow(e);
      }
    } finally {
      setIsFormLoading(false);
    }
  };

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    setGoBackControl({ showGoBack: true, goBackCallback: navigateBack });
  }, [navigateBack, setGoBackControl]);

  return (
    <AuthenticatedPageTemplate>
      <ContentBody>
        <ContentHeader title="Verify your identity" />
        <div className="mt-4 md:mt-10">
          <p className="text-md font-light md:text-lg">
            {`To continue, please enter the code that we sent to `}
            <span className="font-bold">{profile.user.emailAddress}</span>.
          </p>
        </div>
        <div className="mt-4 md:mt-10">
          <p className="text-md font-normal md:text-lg">
            {`Didn't receive a code? `}
            <button
              disabled={!isFormValid || isFormLoading || mfaInvalidated}
              onClick={() => void resendEmail()}
              className="bg-transparent hover:underline focus:outline-none"
            >
              <span className="text-primary font-medium">Send a new code</span>
            </button>
          </p>
        </div>
        <form
          className="replay-redact mt-4 md:mt-10"
          ref={formRef}
          onInput={(ev) => void onInput(ev)}
          onSubmit={(ev) => void onSubmit(ev)}
        >
          <PasscodeInput
            inputName="code"
            inputLength={6}
            regex={"[0-9a-zA-Z]"}
            error={errorMessage}
          />
          <button
            type="submit"
            className="hidden"
          />
        </form>
      </ContentBody>
      <ContentFooter>
        <Button
          label="Continue"
          primary
          fullWidth
          disabled={!isFormValid || isFormLoading || mfaInvalidated}
          loading={isFormLoading}
          onClick={() => formRef?.current?.requestSubmit()}
        />
        <div className="w-full max-md:hidden">
          <GoBackButton onClick={() => navigateBack()} />
        </div>
      </ContentFooter>
    </AuthenticatedPageTemplate>
  );
}
