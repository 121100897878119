import React, { ReactElement, ReactNode, createContext, useEffect, useRef, useState } from "react";

import { MajorSyncErrorType, MinorSyncErrorType } from "../models/error";
import { SyncEventMessage } from "../models/message";
import { useAppDispatch } from "../store/hooks";
import { setTerminalError } from "../store/syncSessionSlice";
import { UUID } from "../types";
import { SimpleWebSocket, WebSocketEvents } from "../websocket";
import { WebSocketCloseStatus } from "../websocket/index";
import SyncWebSocket from "../websocket/sync";

type WebSocketQueueContextInitProps = {
  sessionId?: UUID;
  isWaiting?: boolean;
  setIsWaiting?: (isWaiting: boolean) => void;
  isConnected?: boolean;
  signalReadyToAdvanceQueue?: () => void;
  sendMessage?: (message: SyncEventMessage) => void;
  getCurrentMessage?: () => SyncEventMessage | null;
  closeWebSocket?: () => void;
};

type WebSocketQueueContextExportProps = {
  [K in keyof WebSocketQueueContextInitProps]-?: WebSocketQueueContextInitProps[K];
};

const WebSocketQueueContext = createContext<WebSocketQueueContextInitProps>({});

function WebSocketQueueProvider({ sessionId, children }: { sessionId: UUID; children: ReactNode }): ReactElement {
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [queue, setQueue] = useState<SyncEventMessage[]>([]);
  const [readyToAdvanceQueue, setReadyToAdvanceQueue] = useState<boolean>(false);
  const webSocket = useRef<SyncWebSocket | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const url = process.env.REACT_APP_BASIS_WS_URL;
    if (!url) throw new Error("REACT_APP_BASIS_WS_URL is undefined");
    const ws = new SyncWebSocket(`${url}/sync/${sessionId}`, 500, 60); // 30 seconds

    ws.addEventListener(WebSocketEvents.RetryAttemptsExhausted, () => {
      dispatch(
        setTerminalError({
          majorType: MajorSyncErrorType.Internal,
          minorType: MinorSyncErrorType.Unavailable,
          reason: "Unable to reconnect. Please try again",
        }),
      );
    });
    ws.addEventListener(WebSocketEvents.Close, (instance: SimpleWebSocket, ev: CloseEvent) => {
      if (
        ev.code !== WebSocketCloseStatus.NormalClosure &&
        ev.code !== WebSocketCloseStatus.NoStatusReceived &&
        ev.code !== WebSocketCloseStatus.GoingAway
      ) {
        dispatch(
          setTerminalError({
            majorType: MajorSyncErrorType.Internal,
            minorType: MinorSyncErrorType.Unavailable,
            reason: "There was an error. Please try again",
          }),
        );
      }
      setQueue([]);
    });

    async function handleMessageReceive() {
      for await (const message of ws.receiveMessage()) {
        setQueue((prevQueue) => [...prevQueue, SyncEventMessage.deserialize(message)]);
        setIsWaiting(false);
      }
    }

    ws.connect().catch((err) => {
      throw err;
    });

    void handleMessageReceive();
    webSocket.current = ws;
    return () => {
      if (ws.isConnected) {
        ws.close();
      } else {
        ws.addEventListener(WebSocketEvents.Open, () => ws.close());
      }
    };
  }, [dispatch, sessionId]);

  useEffect(() => {
    if (readyToAdvanceQueue && queue.length - 1 > 0) {
      setQueue((prevQueue) => prevQueue.slice(1));
      setReadyToAdvanceQueue(false);
    }
  }, [queue.length, readyToAdvanceQueue]);

  function signalReadyToAdvanceQueue() {
    if (!readyToAdvanceQueue) {
      setReadyToAdvanceQueue(true);
    }
  }

  function getCurrentMessage(): SyncEventMessage | null {
    return queue.length > 0 ? queue[0] : null;
  }

  return (
    <WebSocketQueueContext.Provider
      value={{
        sessionId,
        isWaiting,
        setIsWaiting,
        isConnected: webSocket.current?.isConnected ?? false,
        signalReadyToAdvanceQueue,
        sendMessage: (message: SyncEventMessage) => webSocket.current?.sendMessage(message),
        getCurrentMessage,
        closeWebSocket: () => webSocket.current?.close(),
      }}
    >
      {children}
    </WebSocketQueueContext.Provider>
  );
}

function MockWebSocketQueueProvider({ children }: { children: ReactNode }): ReactElement {
  const sessionId = "d722a983-030e-4dad-af02-e345e74afa7d" as UUID;

  const [isConnected] = useState<boolean>(true);
  const [isWaiting, setIsWaiting] = useState<boolean>(true);

  function signalReadyToAdvanceQueue() {
    return null;
  }
  function sendMessage(_: SyncEventMessage) {
    return null;
  }
  function getCurrentMessage() {
    return null;
  }
  function closeWebSocket() {
    return null;
  }

  return (
    <WebSocketQueueContext.Provider
      value={{
        sessionId,
        isWaiting,
        setIsWaiting,
        isConnected,
        signalReadyToAdvanceQueue,
        sendMessage,
        getCurrentMessage,
        closeWebSocket,
      }}
    >
      {children}
    </WebSocketQueueContext.Provider>
  );
}

function useWebSocketQueue(): WebSocketQueueContextExportProps {
  const context = React.useContext(WebSocketQueueContext);
  if (!context) {
    throw new Error("useWebSocketQueue must be used within a WebSocketQueueProvider");
  }
  return context as WebSocketQueueContextExportProps;
}

export { WebSocketQueueProvider, MockWebSocketQueueProvider, useWebSocketQueue };
