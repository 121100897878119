import React, { ReactElement } from "react";

export default function TOS(): ReactElement {
  return (
    <p className="text-xs font-light text-navy-400">
      By continuing, you agree to our
      <a
        href="https://usebasis.co/"
        target="_blank"
        rel="noopener noreferrer"
        className="font-medium"
      >
        {` terms of service `}
      </a>
      and
      <a
        href="https://usebasis.co/"
        target="_blank"
        rel="noopener noreferrer"
        className="font-medium"
      >
        {` privacy policy `}
      </a>
      and attest that the provided information is accurate.
    </p>
  );
}
