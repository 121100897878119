import { ArrowLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { ReactElement } from "react";

import { useMobileHeaderBar } from "../../contexts/MobileHeaderBarContext";
import { getOAuthParams } from "../../store/authSlice";
import { getClientConfig } from "../../store/clientConfigSlice";
import { useAppSelector } from "../../store/hooks";
import { convertBase64SVGToImageUrl, handleBackToClient } from "../../utils";
import GoBackButton from "../Buttons/GoBackButton";

export default function HeaderBar(): ReactElement {
  const { customerLogo, customerShortName } = useAppSelector(getClientConfig);
  const auth = useAppSelector(getOAuthParams);
  const {
    goBackControl: { showGoBack, goBackCallback },
  } = useMobileHeaderBar();

  return (
    <div className="flex w-full shrink-0 items-center justify-center border-b-1 border-neutral-300 py-2 text-sm">
      <button
        className="absolute left-2 flex flex-row items-center max-md:hidden"
        onClick={() => void handleBackToClient(false, auth.redirectUri, auth.state)}
      >
        <div className="flex items-center">
          <ArrowLeftIcon className="h-5 w-5 stroke-navy-500" />
          <span className="pl-2">Return to {customerShortName}</span>
        </div>
      </button>
      <div className={`absolute left-2 md:hidden ${!showGoBack ? "hidden" : ""}`}>
        <GoBackButton onClick={() => goBackCallback()} />
      </div>
      <img
        src={convertBase64SVGToImageUrl(customerLogo)}
        alt="customer logo"
        className="h-6 w-auto"
      />
      <button
        className="absolute right-2 md:hidden"
        onClick={() => void handleBackToClient(false, auth.redirectUri, auth.state)}
      >
        <XMarkIcon className="h-6 w-6 stroke-neutral-700" />
      </button>
    </div>
  );
}
