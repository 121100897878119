import { PlusIcon } from "@heroicons/react/24/outline";
import React, { ReactElement } from "react";

interface PlusButtonProps {
  children?: ReactElement;
  onClick: () => void;
}

export default function PlusButton({ children, onClick }: PlusButtonProps): ReactElement {
  return (
    <button
      className="flex flex-row items-center justify-start gap-x-2"
      onClick={onClick}
    >
      <div className="flex flex-col items-center justify-center border-1 border-navy-100">
        <PlusIcon className="m-2 h-5 w-5 stroke-navy-500" />
      </div>
      {children && children}
    </button>
  );
}
