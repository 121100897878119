import { motion } from "framer-motion";
import React, { ReactElement, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useWebSocketQueue } from "../../contexts/WebSocketQueueContext";
import useMotionProps from "../../hooks/useMotionProps";
import useStateNavigation from "../../hooks/useStateNavigate";
import {
  DecisionEventPayload,
  ErrorEventPayload,
  InstructionsEventPayload,
  PromptEventPayload,
  ReportEventPayload,
  ResultReadyEventPayload,
  StageCompleteEventPayload,
  SyncEventType,
} from "../../models/event";
import { SyncEventMessage } from "../../models/message";
import { useAppSelector } from "../../store/hooks";
import { getCurrentSyncSession } from "../../store/syncSessionSlice";
import {
  handleErrorEvent,
  handleReportEvent,
  handleResultReadyEvent,
  handleStageCompleteEvent,
} from "../../sync/handleEvent";
import LoadingSpinnerPadder from "../Suspense/LoadingSpinnerPadder";
import { SyncLoadingSpinner } from "../Suspense/SyncLoadingSpinner";
import { InstructionsMessage } from "./InstructionsMessage";
import { PromptMessage } from "./PromptMessage";
import SyncResultsAndSubUserDecision from "./SyncResultsAndSubUserDecision";

// convenience wrapper to animate into loading state
function MotionSyncLoadingState(): ReactElement {
  const { signalReadyToAdvanceQueue } = useWebSocketQueue();
  const {
    platformConfig: { displayName },
  } = useAppSelector(getCurrentSyncSession);

  useEffect(() => {
    // runs once component is mounted
    signalReadyToAdvanceQueue();
  }, [signalReadyToAdvanceQueue]);

  // Todo 2024-07-12 BAS-1365: make loading spinner height calculation more robust
  return (
    <motion.div
      {...useMotionProps()}
      key="waiting-network"
      className="flex h-full w-full justify-center"
    >
      <LoadingSpinnerPadder>
        <SyncLoadingSpinner platformName={displayName} />
      </LoadingSpinnerPadder>
    </motion.div>
  );
}

export default function SyncViewController({ message }: { message: SyncEventMessage | null }): ReactElement {
  const { isConnected, isWaiting, closeWebSocket } = useWebSocketQueue();
  const motionDivProps = useMotionProps();
  const navigate = useNavigate();
  const { navigateNext, navigateBack } = useStateNavigation();
  const { terminalError, platformId } = useAppSelector(getCurrentSyncSession);
  if (terminalError) {
    throw new Error(JSON.stringify(terminalError));
  }

  useEffect(() => {
    if (!message) return;
    switch (message.payload.eventType) {
      case SyncEventType.Report: {
        // TODO 2024-08-13: replace gross terminal and process event handlers after sync result endpoint is implemented
        const terminal = handleReportEvent(message.payload as ReportEventPayload);
        if (terminal) {
          closeWebSocket();
          navigateNext("/summary", true);
        }
        break;
      }
      case SyncEventType.ResultReady: {
        const terminal = handleResultReadyEvent(message.payload as ResultReadyEventPayload);
        if (terminal) {
          closeWebSocket();
          navigateNext("/summary", true);
        }
        break;
      }
      case SyncEventType.StageComplete: {
        const { payload, sessionId } = message;
        const terminal = handleStageCompleteEvent(payload as StageCompleteEventPayload, sessionId);
        if (terminal) {
          closeWebSocket();
          navigateNext(`/platforms/${platformId}`, true);
        }
        break;
      }
      case SyncEventType.Error: {
        const { payload, sessionId } = message;
        handleErrorEvent(payload as ErrorEventPayload, sessionId);
        break;
      }
      case SyncEventType.Observe: {
        break;
      }
      default:
        break;
    }
  }, [message, closeWebSocket, navigateNext, navigateBack, navigate, platformId]);

  const view: ReactElement = useMemo(() => {
    if (!isConnected || isWaiting) return <MotionSyncLoadingState />;

    switch (message?.payload.eventType) {
      case SyncEventType.Prompt: {
        return (
          <motion.div
            className="h-full w-full"
            {...motionDivProps}
            key={`prompt-${message.payload.eventId}`}
          >
            <PromptMessage {...(message.payload as PromptEventPayload)} />
          </motion.div>
        );
      }
      case SyncEventType.Decision: {
        const { deadline, eventId } = message.payload as DecisionEventPayload;
        return (
          <motion.div
            className="h-full w-full"
            {...motionDivProps}
            key={`decision-${eventId}`}
          >
            <SyncResultsAndSubUserDecision deadline={deadline} />
          </motion.div>
        );
      }
      case SyncEventType.Instructions: {
        return (
          <motion.div
            className="h-full w-full"
            {...motionDivProps}
            key={`instructions-${message.payload.eventId}`}
          >
            <InstructionsMessage {...(message.payload as InstructionsEventPayload)} />
          </motion.div>
        );
      }
      default: {
        return <MotionSyncLoadingState />;
      }
    }
  }, [message, isConnected, isWaiting, motionDivProps]);

  return <div className="flex h-full w-full flex-col items-center">{view}</div>;
}
