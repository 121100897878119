import { ClientConfig, FileResource, Platform, Progress, Resource, ServiceLine, SyncResult } from "../interfaces";
import BaseModel from "../models/base";
import { APIDELETERequest, APIGETRequest, APIPOSTRequest, BaseResponse } from "./apiHandler";

interface InitializationRequest extends BaseModel {
  scope: string;
  redirectUri: string;
  clientId: string;
  codeChallenge: string;
  state: string;
  initToken: string;
}

export interface InitializationResponse extends BaseResponse {
  payload: {
    clientConfig?: ClientConfig;
    location?: string;
  };
}

export async function initialize(params: InitializationRequest): Promise<InitializationResponse> {
  return await APIPOSTRequest<InitializationResponse>("initialize", params);
}

export interface IdentifyRequest extends BaseModel {
  userName?: string;
}

export interface IdentifyResponse extends BaseResponse {
  payload: {
    isVerified: boolean;
    isAuthenticated: boolean;
    isAuthorized: boolean;
  };
}

export async function identify(params: IdentifyRequest): Promise<IdentifyResponse> {
  return await APIPOSTRequest<IdentifyResponse>("identify", params);
}

export interface VerifyRequest extends BaseModel {
  passcode: string;
}

export interface VerifyResponse extends BaseResponse {
  payload: {
    isAuthorized: boolean;
  };
}

export async function verify(params: VerifyRequest): Promise<VerifyResponse> {
  return await APIPOSTRequest<VerifyResponse>("verify", params);
}

export interface PlatformResponse extends BaseResponse {
  payload: Platform;
}

export async function getPlatform(platformId: string): Promise<PlatformResponse> {
  return await APIGETRequest<PlatformResponse>(`platforms/${platformId}`);
}

export interface PlatformsResponse extends BaseResponse {
  platforms: Platform[];
  previous: string;
  next: string;
  total: number;
}

export interface SyncRequest extends BaseModel {
  platformId: string;
  credentials?: { [key: string]: string | number };
}

export interface SyncResponse extends BaseResponse {
  payload: {
    platformId: string;
    syncTargetId: string;
    sessionId: string;
    serviceLineId: string;
  };
}

export async function sync(params: SyncRequest): Promise<SyncResponse> {
  return await APIPOSTRequest<SyncResponse>("sync", params);
}

export interface SyncResultResponse extends BaseModel {
  payload: SyncResult;
}

export async function getSyncResult(syncTargetId: string): Promise<SyncResultResponse> {
  return await APIGETRequest<SyncResultResponse>(`sync/${syncTargetId}/result`);
}

export interface SyncResultsResponse extends BaseModel {
  payload: { results: Array<SyncResult> };
}

export async function getSyncResults(): Promise<SyncResultsResponse> {
  return await APIGETRequest<SyncResultsResponse>(`sync/results`);
}

export interface AuthorizeResponse extends BaseResponse {
  payload: {
    location: string;
  };
}

export async function authorize(): Promise<AuthorizeResponse> {
  return await APIPOSTRequest<AuthorizeResponse>("authorize");
}

export async function resend(): Promise<BaseResponse> {
  return await APIPOSTRequest<BaseResponse>("resend");
}

export async function getPlatforms(
  text: string | null = null,
  slugs: Array<string> | null = null,
): Promise<PlatformsResponse> {
  return await APIGETRequest("platforms", {
    ...(text != null && { text: text }),
    ...(slugs != null && { slug: slugs }),
    limit: 250,
  });
}

export interface ServiceLineRequest extends BaseModel {
  isHealthy?: boolean;
}

export interface ServiceLinesResponse extends BaseResponse {
  payload: {
    serviceLines: Array<ServiceLine>;
  };
}

export async function getServiceLines(params: ServiceLineRequest = {}): Promise<ServiceLinesResponse> {
  return await APIGETRequest("service-lines", params);
}

export interface UploadResourceResponse extends BaseResponse {
  payload: {
    resourceId: string;
  };
}

export interface GetResourcesResponse extends BaseResponse {
  payload: {
    resources: Resource[];
  };
}

export async function getResources(sessionId: string, promptId: string): Promise<GetResourcesResponse> {
  return await APIGETRequest(`sync/${sessionId}/resources`, { prompt_id: promptId });
}

export async function deleteResource(sessionId: string, resourceId: string, promptId: string): Promise<BaseResponse> {
  return await APIDELETERequest(`sync/${sessionId}/resources/${resourceId}`, { promptId: promptId });
}

export function uploadResource(
  fileItem: FileResource,
  promptId: string,
  sessionId: string,
  refs: React.MutableRefObject<{ [key: string]: XMLHttpRequest }>,
  onProgress: (fileId: string, progress: Progress) => void,
): Promise<UploadResourceResponse> {
  return new Promise((resolve, reject) => {
    if (!fileItem.data || !fileItem.fileId) return reject();
    if (!fileItem.data.type.match(/(pdf|png|jpeg)/)) {
      return reject("Unsupported file type");
    }
    const formData = new FormData();
    formData.append("resource", fileItem.data);
    formData.append("prompt_id", promptId);

    const xhr = (refs.current[fileItem.fileId] = new XMLHttpRequest());
    xhr.withCredentials = true;

    xhr.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const progress = { current: event.loaded, total: event.total };
        onProgress(fileItem.fileId, progress);
      }
    });

    xhr.addEventListener("load", () => {
      const resp = BaseModel.deserialize<UploadResourceResponse>(xhr.responseText);
      if (resp.result.success) {
        resolve(resp);
      } else {
        reject("Upload failed");
      }
    });

    xhr.addEventListener("error", () => {
      reject("Upload failed");
    });

    xhr.open("POST", `${process.env.REACT_APP_BASIS_API_URL ?? ""}/sync/${sessionId}/resources`);
    xhr.send(formData);
  });
}
