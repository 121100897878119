import { BuildingLibraryIcon } from "@heroicons/react/24/outline";
import React, { ReactElement } from "react";

import { convertBase64SVGToImageUrl } from "../utils";

type PlatformIconProps = Readonly<{
  icon: string | null;
}>;

export default function PlatformIcon({ icon }: PlatformIconProps): ReactElement {
  return (
    <div className="flex h-12 w-12 items-center justify-center overflow-hidden">
      {icon ? (
        <img
          src={convertBase64SVGToImageUrl(icon)}
          alt="Platform logo"
        />
      ) : (
        <BuildingLibraryIcon className="border border-gray-300 p-1" />
      )}
    </div>
  );
}
