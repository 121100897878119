import { CheckCircleIcon, ClockIcon, ExclamationCircleIcon } from "@heroicons/react/24/solid";
import React, { ReactElement } from "react";

import { Account, SyncResult } from "../../interfaces";

interface FetchAccountsSummaryProps {
  syncResult: SyncResult;
}

export default function FetchAccountsSummary({ syncResult }: FetchAccountsSummaryProps): ReactElement {
  const { accounts, subuserAuthorization, subuserRequest } = syncResult;

  const subuserRequestNoSubuser = subuserRequest != null && subuserAuthorization == null;
  const subuserCreatedNoAccountData = subuserAuthorization && accounts.length === 0;
  const getAccountRepr = (idx: number, acct: Account): string =>
    [acct.displayName, acct.accountMask && `****${acct.accountMask}`].filter((e) => e).join(" ") || `Account ${idx}`;

  return accounts.length ? (
    <div className="flex flex-col">
      <div className="flex flex-row items-center space-x-2.5">
        <CheckCircleIcon className="h-6 w-6 text-green-500" />
        <span className="text-sm font-semibold text-navy-500 md:text-lg">Account data retrieved</span>
      </div>
      <div className="ml-8 pl-0.5 text-sm text-navy-300 md:text-lg">
        {accounts.map((a, i) => (
          <div key={i}>{getAccountRepr(i, a)}</div>
        ))}
      </div>
    </div>
  ) : (
    <div className="flex flex-col">
      <div className="flex flex-row items-center space-x-2.5">
        {subuserCreatedNoAccountData ? (
          <ClockIcon className="h-6 w-6 text-amber-500" />
        ) : (
          <ExclamationCircleIcon className="h-6 w-6 text-red-600" />
        )}
        <span className="text-sm font-semibold text-navy-500 md:text-lg">
          {subuserRequestNoSubuser || subuserCreatedNoAccountData
            ? "Account data pending retrieval"
            : "Account data not retrieved"}
        </span>
      </div>
      <div className="ml-8 pl-0.5 text-sm text-navy-300 md:text-lg">
        {`Your account data was not retrieved${
          subuserCreatedNoAccountData
            ? ", but we will retry later. No further action is necessary."
            : ". Please add another account or try again later."
        }`}
      </div>
    </div>
  );
}
