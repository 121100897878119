import ExclamationCircleIcon from "@heroicons/react/24/outline/ExclamationCircleIcon";
import React, { ReactElement } from "react";

import ContentBody from "../../pages/template/ContentBody";
import PageTemplate from "../../pages/template/PageTemplate";
import ContentHeader from "../ContentHeader";

interface GlobalErrorScreenProps {
  titleText: string;
  body: ReactElement;
}

export default function GlobalErrorScreen({ titleText, body }: GlobalErrorScreenProps): ReactElement {
  return (
    <div className="flex h-full w-full flex-col">
      <div className="h-full min-h-0 w-full grow">
        <div className="flex h-full w-full flex-col items-center">
          <PageTemplate>
            <ContentBody>
              <div className="mb-8 flex w-full flex-row justify-start">
                <ExclamationCircleIcon className="mr-4 h-10 w-10 fill-red-500 stroke-white" />
                <ContentHeader title={titleText} />
              </div>
              <div className="ml-2">{body}</div>
            </ContentBody>
          </PageTemplate>
        </div>
      </div>
    </div>
  );
}
