import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { ErrorBoundary } from "@sentry/react";
import React, { ReactElement, Suspense, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useWebSocketQueue } from "../../contexts/WebSocketQueueContext";
import { ChoiceEventPayload, SyncEventType, UserDecisionType } from "../../models/event";
import { SyncEventMessage } from "../../models/message";
import ContentBody from "../../pages/template/ContentBody";
import { ContentFooter } from "../../pages/template/ContentFooter";
import { getClientConfig } from "../../store/clientConfigSlice";
import { useAppSelector } from "../../store/hooks";
import { getCurrentSyncSession } from "../../store/syncSessionSlice";
import { UUID } from "../../types";
import { Button } from "../Buttons/Button";
import ContentHeader from "../ContentHeader";
import { PaddedPlatformLogo } from "../PlatformLogo";
import { SyncResultSummary, SyncResultSummarySuspense } from "../Results/SyncResultSummary";
import { OngoingConnectivityChoiceProps } from "../SyncMessages/SyncResultsAndSubUserDecision";
import { SubuserChoiceStatus } from "./OngoingConnectivityCommon";

export function OngoingConnectivityStart({
  setSubuserChoice,
  subuserChoice,
  deadlineIndicator,
  disabled = false,
}: OngoingConnectivityChoiceProps): ReactElement {
  const {
    platformConfig: { logo, displayName },
    syncTargetId,
  } = useAppSelector(getCurrentSyncSession);
  const clientConfig = useAppSelector(getClientConfig);
  const { sendMessage, sessionId, setIsWaiting, signalReadyToAdvanceQueue } = useWebSocketQueue();

  const [submitted, setSubmitted] = useState<boolean>(false);

  const onOptIn = () => {
    const payload = {
      eventType: SyncEventType.Choice,
      eventId: uuidv4() as UUID,
      choice: true,
      decisionType: UserDecisionType.CreateSubUser,
    } as ChoiceEventPayload;
    const message = new SyncEventMessage({ messageId: uuidv4() as UUID, sessionId, payload });

    setSubmitted(true);
    sendMessage(message);
    setSubuserChoice(SubuserChoiceStatus.OptedIn);
    signalReadyToAdvanceQueue();
    setIsWaiting(true);
  };

  return (
    <div className="flex h-full w-full flex-col items-center">
      <ContentBody>
        <div className="flex items-center space-x-2 md:space-x-3">
          <CheckCircleIcon className="h-6 w-6 text-green-500 md:h-10 md:w-10" />
          <ContentHeader title="Connection Successful!" />
        </div>
        <div className="mt-6 md:mt-10">
          <ErrorBoundary>
            <Suspense fallback={<SyncResultSummarySuspense />}>
              <SyncResultSummary syncTargetId={syncTargetId} />
            </Suspense>
          </ErrorBoundary>
        </div>
        <div className="mt-6 md:mt-10">
          <div className="text-md flex flex-col gap-y-4 text-navy-500 md:gap-y-5 md:text-lg">
            <p>{clientConfig.customerShortName} needs up-to-date account data.</p>
            <p>
              Enabling updates configures read-only access to your account. You can always remove this access on your
              bank’s website.
            </p>
          </div>
        </div>
        <PaddedPlatformLogo
          logo={logo}
          displayName={displayName}
        />
      </ContentBody>
      <ContentFooter>
        {deadlineIndicator}
        <div className="flex w-full flex-col gap-y-2 md:gap-y-4">
          <Button
            primary
            fullWidth
            label="Enable updates"
            onClick={() => onOptIn()}
            disabled={disabled || submitted}
            loading={submitted && subuserChoice === SubuserChoiceStatus.OptedIn}
          />
          <Button
            fullWidth
            label="Do not enable updates"
            onClick={() => {
              setSubmitted(true);
              setSubuserChoice(SubuserChoiceStatus.Advisory);
            }}
            disabled={disabled || submitted}
            loading={submitted && subuserChoice === SubuserChoiceStatus.Advisory}
          />
        </div>
      </ContentFooter>
    </div>
  );
}
