import React, { useState } from "react";

import { getPlatform, sync } from "../../api/connectApi";
import useAsyncError from "../../hooks/useAsyncError";
import useStateNavigate from "../../hooks/useStateNavigate";
import { useAppDispatch } from "../../store/hooks";
import { createSyncSession } from "../../store/syncSessionSlice";

export default function UploadSyncButton(props: React.ButtonHTMLAttributes<HTMLButtonElement>) {
  const asyncThrow = useAsyncError();
  const dispatch = useAppDispatch();
  const { navigateNext } = useStateNavigate();
  const [isLoading, setIsLoading] = useState(false);

  async function startUploadSync(): Promise<void> {
    setIsLoading(true);
    try {
      const platformResp = await getPlatform("co.usebasis.upload");
      if (!platformResp.result.success) {
        throw new Error("Failed to retrieve upload platform");
      }

      const uploadPlatform = platformResp.payload;
      const resp = await sync({ platformId: uploadPlatform.platformId, credentials: {} });

      if (!resp.result.success) {
        throw new Error("Sync operation failed");
      }

      dispatch(
        createSyncSession({
          ...resp.payload,
          platformConfig: uploadPlatform,
          error: null,
          terminalError: null,
        }),
      );
      navigateNext("/sync");
    } catch (e) {
      asyncThrow(e);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <button
      {...props}
      className="cursor-pointer underline"
      onClick={() => void startUploadSync()}
      disabled={isLoading}
    >
      {props.children}
    </button>
  );
}
