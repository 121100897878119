import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

type GoBackControlProps = {
  showGoBack: boolean;
  goBackCallback: () => void;
};

type MobileHeaderBarContextProps = {
  goBackControl: GoBackControlProps;
  setGoBackControl: (props: GoBackControlProps) => void;
};

const defaultProps: GoBackControlProps = { showGoBack: false, goBackCallback: () => void 0 };

const MobileHeaderBarContext = createContext<MobileHeaderBarContextProps | undefined>(undefined);

function MobileHeaderBarProvider({ children }: { children: ReactNode }): ReactElement {
  const [goBackControl, setGoBackControl] = useState<GoBackControlProps>(defaultProps);
  const location = useLocation();

  //  TODO: 2024-09-24 - BAS-1397 - eliminate provider's dependence on router's location, allowing it to live above the router
  useLayoutEffect(() => {
    // reset decision to render goBack when location changes
    setGoBackControl(defaultProps);
  }, [location.pathname]);

  const setGoBackControlCallback = useCallback((props: GoBackControlProps) => {
    setGoBackControl(props);
  }, []);

  return (
    <MobileHeaderBarContext.Provider value={{ goBackControl, setGoBackControl: setGoBackControlCallback }}>
      {children}
    </MobileHeaderBarContext.Provider>
  );
}

function useMobileHeaderBar(): MobileHeaderBarContextProps {
  const context = useContext(MobileHeaderBarContext);
  if (context === undefined) {
    throw new Error("useMobileMobileHeaderBar must be used within a MobileHeaderBarProvider");
  }
  return context;
}

export { MobileHeaderBarProvider, useMobileHeaderBar };
