import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import React from "react";

export interface goBackButtonProps {
  onClick: () => void;
}

export default function GoBackButton({ onClick }: goBackButtonProps) {
  return (
    <button
      className="flex flex-row items-center"
      onClick={onClick}
    >
      <ArrowLeftIcon className="h-6 w-6 stroke-neutral-700" />
      <span className="ml-2 text-lg text-neutral-700 max-md:hidden md:ml-1">Go back</span>
    </button>
  );
}
