import { LockClosedIcon } from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/react";
import {
  Connect,
  ConnectCancelEvent,
  ConnectDoneEvent,
  ConnectErrorEvent,
  ConnectEventHandlers,
  ConnectOptions,
  ConnectRouteEvent,
} from "connect-web-sdk";
import React, { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { suspend } from "suspend-react";

import { RequestError } from "../api/apiHandler";
import { getPlatform, sync } from "../api/connectApi";
import { Button } from "../components/Buttons/Button";
import GoBackButton from "../components/Buttons/GoBackButton";
import ContentHeader from "../components/ContentHeader";
import { FormTextField } from "../components/FormTextField";
import { PaddedPlatformLogo } from "../components/PlatformLogo";
import PromptErrorMessage from "../components/SyncMessages/PromptErrorMessage";
import { useMobileHeaderBar } from "../contexts/MobileHeaderBarContext";
import useAsyncError from "../hooks/useAsyncError";
import useStateNavigate from "../hooks/useStateNavigate";
import { Platform, PlatformAggregatorType } from "../interfaces";
import { MajorSyncErrorType, MinorSyncErrorType, SyncError } from "../models/error";
import { Prompt, PromptType } from "../models/prompt";
import { getInitTokenParams } from "../store/authSlice";
import { getClientConfig } from "../store/clientConfigSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { createSyncSession, getError } from "../store/syncSessionSlice";
import { parseFormData } from "../utils";
import { AuthenticatedPage } from "./template/AuthenticatedPageTemplate";
import ContentBody from "./template/ContentBody";
import { ContentFooter } from "./template/ContentFooter";
import PageTemplate from "./template/PageTemplate";

interface ConnectUserEvent {
  action?: string;
  institutionLoginId?: number;
  [key: string]: unknown;
}

export default function PlatformView(): ReactElement {
  const asyncThrow = useAsyncError();
  const { navigateNext, navigateBack } = useStateNavigate();
  const initTokenParams = useAppSelector(getInitTokenParams);
  const { platformId: pathPlatformId } = useParams<{ platformId: string }>();
  const platformId = pathPlatformId ?? initTokenParams?.platformId;
  const dispatch = useAppDispatch();
  const clientConfig = useAppSelector(getClientConfig);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isFormLoading, setIsFormLoading] = useState<boolean>(false);
  const institutionLoginIdRef = useRef<number>();
  const debug = process.env.REACT_APP_HOST_ENV === "local";
  const { setGoBackControl } = useMobileHeaderBar();
  const [formError, setFormError] = useState<SyncError | null>(useAppSelector(getError));
  const initialHistoryLength = useRef<number>(window.history.length);

  const platform: Platform | null = suspend(async () => {
    if (!platformId) return null;
    const resp = await getPlatform(platformId);
    if (!resp.result.success) throw new Error("Failed to fetch platform");
    return resp.payload;
  }, [platformId]);

  function onInput(ev: React.FormEvent<HTMLFormElement>): void {
    setIsFormValid(ev.currentTarget.checkValidity());
  }

  async function onSubmit(ev: React.FormEvent<HTMLFormElement>): Promise<void> {
    ev.preventDefault();
    setFormError(null);
    setIsFormLoading(true);
    const formData = new FormData(ev.currentTarget);
    const credentials = parseFormData(formData);
    await startSyncSession(credentials);
    setIsFormLoading(false);
  }

  const startSyncSession = useCallback(
    async (credentials: { [key: string]: string | number }) => {
      if (!platform) return null;
      try {
        const resp = await sync({ platformId: platform.platformId, credentials });

        if (resp.result.success) {
          dispatch(
            createSyncSession({
              ...resp.payload,
              platformConfig: platform,
              error: null,
              terminalError: null,
            }),
          );

          navigateNext("/sync");
        }
      } catch (e) {
        if (e instanceof RequestError) {
          setFormError({
            majorType: MajorSyncErrorType.Internal,
            minorType: MinorSyncErrorType.Unknown,
            reason: "",
          });
        } else {
          asyncThrow(e);
        }
      }
    },
    [asyncThrow, dispatch, navigateNext, platform],
  );

  const openFinicityConnect = useCallback(() => {
    const opts: ConnectOptions = {
      overlay: "rgba(199,201,199, 0.5)",
      selector: "#aggregator-connect",
    };

    // TODO 2024-10-29: this is a terrible hack. Iframe navigation affects the parent navigation
    // Figure out a better way to handle this
    const countHistorySteps = () => {
      return window.history.length - initialHistoryLength.current + 1;
    };

    const evHandlers: ConnectEventHandlers = {
      onDone: (ev: ConnectDoneEvent) => {
        if (debug) console.log("onDone", ev);
        Sentry.addBreadcrumb({
          category: "finicity",
          message: "Finicity Connect onDone",
          level: "info",
          data: { event: ev },
        });
        if (!platform) return null;
        if (ev.code === 200 && platform.aggregatorUsername && institutionLoginIdRef.current) {
          const creds = {
            customer_username: platform.aggregatorUsername,
            institution_login_id: institutionLoginIdRef.current,
          };
          void startSyncSession(creds);
        }
      },
      onCancel: (ev: ConnectCancelEvent) => {
        if (debug) console.log("onCancel", ev);
        Sentry.addBreadcrumb({
          category: "finicity",
          message: "Finicity Connect onCancel",
          level: "info",
          data: { event: ev },
        });
        navigateBack(countHistorySteps());
      },
      onError: (ev: ConnectErrorEvent) => {
        if (debug) console.log("onError", ev);
        Sentry.captureException(new Error("Finicity Connect onError"), { extra: { event: ev } });
        navigateBack(countHistorySteps());
      },
      onRoute: (ev: ConnectRouteEvent) => {
        if (debug) console.log("onRoute", ev);
        Sentry.addBreadcrumb({
          category: "finicity",
          message: "Finicity Connect onRoute",
          level: "info",
          data: { event: ev },
        });
      },
      onUser: (ev: ConnectUserEvent) => {
        if (debug) console.log("onUser", ev);
        Sentry.addBreadcrumb({
          category: "finicity",
          message: "Finicity Connect onUser",
          level: "info",
          data: { event: ev },
        });
        if ((ev.action === "AddAccounts" || ev.action === "OAuthAddAccounts") && ev.institutionLoginId) {
          institutionLoginIdRef.current = ev.institutionLoginId;
        }
      },
      onLoad: () => {
        if (debug) console.log("loaded");
        Sentry.addBreadcrumb({ category: "finicity", message: "Finicity Connect onLoad", level: "info" });
      },
    };

    try {
      if (platform?.aggregatorUrl) Connect.launch(platform.aggregatorUrl, evHandlers, opts);
    } catch (e) {
      asyncThrow(e);
    }
  }, [debug, platform, startSyncSession, navigateBack, asyncThrow]);

  useEffect(() => {
    if (platform?.aggregator?.aggregatorType === PlatformAggregatorType.Finicity && !institutionLoginIdRef.current)
      void openFinicityConnect();
    return () => {
      Connect.destroy();
    };
  }, [platform, openFinicityConnect]);

  const formRef = useRef<HTMLFormElement>(null);

  const platformIsAggregator = platform?.aggregator;

  useEffect(() => {
    if (!platformIsAggregator) {
      setGoBackControl({ showGoBack: true, goBackCallback: navigateBack });
    }
  }, [navigateBack, platformIsAggregator, setGoBackControl]);

  return (
    <AuthenticatedPage>
      <div
        id="aggregator-connect"
        className={
          platformIsAggregator
            ? "relative mx-auto flex h-full max-h-[800px] w-full max-w-2xl flex-col md:mt-24"
            : "hidden"
        }
      />
      <div className={!platformIsAggregator ? "flex h-full w-full flex-col items-center" : "hidden"}>
        <PageTemplate>
          <ContentBody>
            <ContentHeader title="Connect to your bank" />
            <div className="mt-4 md:mt-10">
              <p className="text-md md:text-lg">
                Please enter your details below. This authorizes Basis to gather your data on
                {` ${clientConfig.customerShortName}'s`} behalf.
              </p>
            </div>
            {platform && (
              <PaddedPlatformLogo
                logo={platform.logo}
                displayName={platform.displayName}
              />
            )}
            <form
              className="replay-redact mt-2 flex flex-col gap-y-6 md:mb-4 md:mt-8 md:gap-y-8"
              ref={formRef}
              onInput={(ev) => void onInput(ev)}
              onSubmit={(ev) => void onSubmit(ev)}
            >
              {platform?.credentialPrompts.map((prompt: Prompt) => {
                if (prompt.promptType === PromptType.Text) {
                  return (
                    <FormTextField
                      key={prompt.promptId}
                      name={prompt.promptId}
                      pattern="^.+$"
                      label={prompt.label}
                      defaultValue={prompt.defaultResponse || ""}
                      readOnly={prompt.defaultResponse !== null}
                      type="text"
                      required
                      fullWidth
                    />
                  );
                } else if (prompt.promptType === PromptType.Numeric) {
                  return (
                    <FormTextField
                      key={prompt.promptId}
                      name={prompt.promptId}
                      label={prompt.label}
                      defaultValue={prompt.defaultResponse || ""}
                      readOnly={prompt.defaultResponse !== null}
                      type="number"
                      required
                      fullWidth
                    />
                  );
                } else if (prompt.promptType === PromptType.Password) {
                  return (
                    <FormTextField
                      key={prompt.promptId}
                      name={prompt.promptId}
                      label={prompt.label}
                      type="password"
                      required
                      fullWidth
                      autoComplete="current-password"
                    />
                  );
                } else {
                  return null;
                }
              })}
              <button
                type="submit"
                className="hidden"
              />
            </form>
          </ContentBody>
          <ContentFooter>
            <Button
              label="Continue"
              primary
              fullWidth
              disabled={!isFormValid || isFormLoading}
              loading={isFormLoading}
              icon={<LockClosedIcon className="h-5 w-5" />}
              onClick={() => formRef?.current?.requestSubmit()}
            />
            {formError && <PromptErrorMessage error={formError} />}
            <div className="w-full max-md:hidden">
              <GoBackButton onClick={() => navigateBack()} />
            </div>
          </ContentFooter>
        </PageTemplate>
      </div>
    </AuthenticatedPage>
  );
}
