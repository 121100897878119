import React, { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { suspend } from "suspend-react";

import { getSyncResult, getSyncResults } from "../../api/connectApi";
import useAsyncError from "../../hooks/useAsyncError";
import { SyncResult } from "../../interfaces";
import { useAppSelector } from "../../store/hooks";
import { getCurrentSyncSession } from "../../store/syncSessionSlice";
import { SubuserStrategy } from "../OngoingConnectivity/OngoingConnectivityCommon";
import { LoadingSpinner } from "../Suspense/Loading";
import FetchAccountsSummary from "./FetchAccountsSummary";
import OngoingConnectivitySummary from "./OngoingConnectivitySummary";

interface SyncResultSummaryViewProps {
  syncResult: SyncResult;
  showOngoingConnectivitySummary?: boolean;
}

export function SyncResultSummaryView({
  syncResult,
  showOngoingConnectivitySummary = false,
}: SyncResultSummaryViewProps): ReactElement {
  return (
    <div className="flex w-full flex-col rounded border border-navy-100 p-2 md:p-4">
      <h2 className="text-md mb-1 font-bold text-navy-500 md:mb-2 md:text-xl">{syncResult.platform.name}</h2>
      <div>
        <div className="flex flex-col gap-y-2 md:gap-y-4">
          <FetchAccountsSummary syncResult={syncResult} />
          {showOngoingConnectivitySummary && <OngoingConnectivitySummary syncResult={syncResult} />}
        </div>
      </div>
    </div>
  );
}

interface SyncResultSummaryProps {
  syncTargetId: string;
}

export function SyncResultSummary({ syncTargetId }: SyncResultSummaryProps): ReactElement {
  const location = useLocation();
  const asyncThrow = useAsyncError();
  const syncResult =
    suspend(async () => {
      try {
        const syncResultResp = await getSyncResult(syncTargetId);
        return syncResultResp.payload;
      } catch (e) {
        asyncThrow(e);
      }
    }, [syncTargetId, location.pathname]) ?? null;

  if (!syncResult) return <></>;

  return (
    <div className="flex w-full flex-col items-center justify-center">
      <SyncResultSummaryView
        syncResult={syncResult}
        showOngoingConnectivitySummary={
          syncResult.syncTargetParameters.createUser &&
          syncResult.platform.subuserStrategy !== SubuserStrategy.Unsupported &&
          location.pathname === "/summary"
        }
      />
    </div>
  );
}

interface SyncResultSummariesViewProps {
  syncResults: Array<SyncResult>;
}

export function SyncResultSummariesView({ syncResults }: SyncResultSummariesViewProps): ReactElement {
  return (
    <div className="flex h-full w-full flex-col items-center justify-start gap-y-4">
      {syncResults.map((result, i) => (
        <SyncResultSummaryView
          key={i}
          syncResult={result}
          showOngoingConnectivitySummary={
            result.syncTargetParameters.createUser && result.platform.subuserStrategy !== SubuserStrategy.Unsupported
          }
        />
      ))}
    </div>
  );
}

export function SyncResultSummaries(): ReactElement {
  const asyncThrow = useAsyncError();

  const { sessionId } = useAppSelector(getCurrentSyncSession);
  const syncResults =
    suspend(async () => {
      try {
        const syncResultsResp = await getSyncResults();
        return syncResultsResp.payload.results;
      } catch (e) {
        asyncThrow(e);
      }
    }, [sessionId]) ?? null;

  if (!syncResults) return <></>;

  return <SyncResultSummariesView syncResults={syncResults} />;
}

export function SyncResultSummarySuspense(): ReactElement {
  // TODO: 2024-08-20 - BAS-1445 - Implement loading shimmers for <SyncResultSummaryBox/> loading states
  return (
    <div className="flex h-36 w-full flex-col items-center justify-center rounded border border-navy-100 p-2 md:p-4">
      <LoadingSpinner />
    </div>
  );
}
