import "./index.css";

import * as Sentry from "@sentry/react";
import { AnimatePresence } from "framer-motion";
import { ReactElement, ReactNode, Suspense, useEffect } from "react";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import AboutBasis from "./components/AboutBasis";
import AppErrorFallback from "./components/Exception/AppErrorFallback";
import NotFoundFallback from "./components/Exception/NotFoundFallback";
import HeaderBar from "./components/Layout/HeaderBar";
import { LoadingSpinner } from "./components/Suspense/Loading";
import LoadingSpinnerPadder from "./components/Suspense/LoadingSpinnerPadder";
import { ENTRY_PATH } from "./constants/constants";
import { AnimationStateProvider } from "./contexts/AnimationStateContext";
import { MobileHeaderBarProvider } from "./contexts/MobileHeaderBarContext";
import { useColors } from "./hooks/useColors";
import AnimatedPageOutlet from "./pages/AnimatedPageOutlet";
import Auth from "./pages/Auth";
import ConnectedServiceLines from "./pages/ConnectedServiceLines";
import InstitutionSearch from "./pages/InstitutionSearch";
import LandingPage from "./pages/Land";
import Platform from "./pages/Platform";
import Summary from "./pages/Summary";
import Sync from "./pages/Sync";
import PageTemplate from "./pages/template/PageTemplate";
import VerifyEmail from "./pages/VerifyEmail";
import reportWebVitals from "./reportWebVitals";
import { store } from "./store";

const SENTRY_REPLAY_ENVS = ["development", "production"];
const enableReplayInEnvs = (envs: string[]): boolean =>
  envs.includes(process.env.REACT_APP_HOST_ENV as string) ? true : false;

Sentry.init({
  enabled: process.env.REACT_APP_USE_SENTRY === "true",
  // https://basis-lf.sentry.io/projects/connect-ui/?project=4506396583460864
  dsn: "https://9499b4e9668ea238251574a27e3f24ec@o4506384299327488.ingest.sentry.io/4506396583460864",
  environment: process.env.REACT_APP_HOST_ENV,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      maskAllInputs: false,
      blockAllMedia: false,
      mask: [".replay-redact"],
      networkDetailAllowUrls: [`${process.env.REACT_APP_BASIS_API_URL ?? ""}/initialize`],
      networkCaptureBodies: true,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/\//, /.*usebasis\.co.*/],

  // Capture Replay for 100% of all sessions, plus for 100% of sessions with an error
  replaysSessionSampleRate: enableReplayInEnvs(SENTRY_REPLAY_ENVS) ? 1.0 : 0.0,
  replaysOnErrorSampleRate: enableReplayInEnvs(SENTRY_REPLAY_ENVS) ? 1.0 : 0.0,
});

const container = document.getElementById("root") as Element;
const root = createRoot(container);

const router = Sentry.wrapCreateBrowserRouter(createBrowserRouter)([
  {
    element: (
      <MobileHeaderBarProvider>
        <Sentry.ErrorBoundary fallback={<AppErrorFallback />}>
          <Suspense
            fallback={
              <PageTemplate>
                <LoadingSpinnerPadder>
                  <LoadingSpinner />
                </LoadingSpinnerPadder>
              </PageTemplate>
            }
          >
            <Template />
          </Suspense>
        </Sentry.ErrorBoundary>
      </MobileHeaderBarProvider>
    ),
    children: [
      {
        path: ENTRY_PATH,
        element: <LandingPage />,
      },
      {
        path: "auth",
        element: <Auth />,
      },
      {
        path: "verify",
        element: <VerifyEmail />,
      },
      {
        path: "connected",
        element: <ConnectedServiceLines />,
      },
      {
        path: "sync",
        element: <Sync />,
      },
      {
        path: "platforms/:platformId",
        element: <Platform />,
      },
      {
        path: "platforms",
        element: <InstitutionSearch />,
      },
      {
        path: "summary",
        element: <Summary />,
      },
      {
        path: "*",
        //  TODO: 2024-09-24 - BAS-1397 - Refactor MHBP to BackButtonProvider and move above router, or restructure app
        element: <NotFoundFallback />,
      },
    ],
  },
]);

function ThemeProvider({ children }: { children: ReactNode }): ReactElement {
  const colors = useColors();
  return (
    <div
      className="h-full w-full"
      style={{
        ["--accent-fill-color" as string]: colors.accentFillColor,
        ["--accent-fill-color-muted" as string]: colors.accentFillColorMuted,
        ["--accent-text-color" as string]: colors.accentTextColor,
        ["--accent-text-color-muted" as string]: colors.accentTextColorMuted,
      }}
    >
      {children}
    </div>
  );
}

function Providers({ children }: { children: ReactNode }): ReactElement {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider>{children}</ThemeProvider>
    </ReduxProvider>
  );
}

function Template(): ReactElement {
  return (
    <div className="flex h-[100dvh] w-screen flex-col antialiased">
      <HeaderBar />
      <div className="flex h-full min-h-0 w-full grow">
        <div className="absolute bottom-0 m-6 max-md:hidden">
          <AboutBasis header="logo" />
        </div>
        <div className="grow overflow-y-auto">
          <AnimationStateProvider>
            <AnimatePresence mode="wait">
              <AnimatedPageOutlet />
            </AnimatePresence>
          </AnimationStateProvider>
        </div>
      </div>
    </div>
  );
}

root.render(
  <React.StrictMode>
    <Providers>
      <RouterProvider router={router} />
    </Providers>
  </React.StrictMode>,
);

// TODO 2024-11-09: do something with this or remove it.
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
