import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { ClientConfig } from "../interfaces";
import { RootState } from "./index";

const initialState: ClientConfig = {
  clientId: "",
  customerShortName: "",
  customerFullName: "",
  customerLogo: "",
  trustEmail: false,
  landHeader: null,
  landSubtitle: null,
  landBody: null,
  landServiceInfo: null,
  landCTA: null,
  summaryTitle: null,
  summarySubtitle: null,
  summaryBody: null,
  summaryCTA: null,
  accentFillColor: null,
  accentTextColor: null,
  defaultPlatformSlugs: null,
};

export const clientConfigSlice = createSlice({
  name: "clientConfig",
  initialState,
  reducers: {
    setClientConfig: (state, action: PayloadAction<ClientConfig>) => {
      state = action.payload;
      return state;
    },
  },
});

export const { setClientConfig } = clientConfigSlice.actions;

export const getClientConfig = (state: RootState) => state.clientConfig;

export default clientConfigSlice.reducer;
