import React, { ReactElement, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { useMobileHeaderBar } from "../../contexts/MobileHeaderBarContext";
import { useWebSocketQueue } from "../../contexts/WebSocketQueueContext";
import { ChoiceEventPayload, SyncEventType, UserDecisionType } from "../../models/event";
import { SyncEventMessage } from "../../models/message";
import ContentBody from "../../pages/template/ContentBody";
import { ContentFooter } from "../../pages/template/ContentFooter";
import { getClientConfig } from "../../store/clientConfigSlice";
import { useAppSelector } from "../../store/hooks";
import { getCurrentSyncSession } from "../../store/syncSessionSlice";
import { UUID } from "../../types";
import { Button } from "../Buttons/Button";
import GoBackButton from "../Buttons/GoBackButton";
import ContentHeader from "../ContentHeader";
import { PaddedPlatformLogo } from "../PlatformLogo";
import { OngoingConnectivityChoiceProps } from "../SyncMessages/SyncResultsAndSubUserDecision";
import { SubuserChoiceStatus } from "./OngoingConnectivityCommon";

export function OngoingConnectivityAdvisory({
  setSubuserChoice,
  subuserChoice,
  deadlineIndicator,
  disabled = false,
}: OngoingConnectivityChoiceProps): ReactElement {
  const clientConfig = useAppSelector(getClientConfig);
  const {
    platformConfig: { logo, displayName },
  } = useAppSelector(getCurrentSyncSession);
  const { sendMessage, sessionId, setIsWaiting, signalReadyToAdvanceQueue } = useWebSocketQueue();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { setGoBackControl } = useMobileHeaderBar();

  const onOptIn = () => {
    const payload = {
      eventType: SyncEventType.Choice,
      eventId: uuidv4() as UUID,
      choice: true,
      decisionType: UserDecisionType.CreateSubUser,
    } as ChoiceEventPayload;
    const message = new SyncEventMessage({ messageId: uuidv4() as UUID, sessionId, payload });

    setSubmitted(true);
    sendMessage(message);
    setSubuserChoice(SubuserChoiceStatus.OptedIn);
    signalReadyToAdvanceQueue();
    setIsWaiting(true);
  };

  const onOptOut = () => {
    const payload = {
      eventType: SyncEventType.Choice,
      eventId: uuidv4() as UUID,
      choice: false,
      decisionType: UserDecisionType.CreateSubUser,
    } as ChoiceEventPayload;
    const message = new SyncEventMessage({ messageId: uuidv4() as UUID, sessionId, payload });

    setSubmitted(true);
    sendMessage(message);
    setSubuserChoice(SubuserChoiceStatus.OptedOut);
    signalReadyToAdvanceQueue();
    setIsWaiting(true);
  };

  useEffect(() => {
    setGoBackControl({ showGoBack: true, goBackCallback: () => setSubuserChoice(SubuserChoiceStatus.Start) });
  }, [setGoBackControl, setSubuserChoice]);

  return (
    <div className="flex h-full w-full flex-col items-center">
      <ContentBody>
        <ContentHeader title="Are you sure?" />
        <div className="mt-4 md:mt-10">
          <div className="text-md flex flex-col gap-y-4 text-navy-500 md:gap-y-5 md:text-lg">
            <p>{clientConfig.customerShortName} needs up-to-date account data.</p>
            <p>
              To do this, Basis will add an authorized user to your {displayName} account with read-only access. You
              will always be able to delete that user from your bank&apos;s online portal.
            </p>
            <p>Opting out of updates will disrupt your {clientConfig.customerShortName} service.</p>
          </div>
        </div>
        <PaddedPlatformLogo
          logo={logo}
          displayName={displayName}
        />
      </ContentBody>
      <ContentFooter>
        {deadlineIndicator}
        <div className="flex w-full flex-col gap-y-2 md:gap-y-4">
          <Button
            primary
            fullWidth
            label="Enable updates"
            onClick={() => onOptIn()}
            disabled={disabled || submitted}
            loading={submitted && subuserChoice === SubuserChoiceStatus.OptedIn}
          />
          <Button
            fullWidth
            label="Do not enable updates"
            onClick={() => onOptOut()}
            disabled={disabled || submitted}
            loading={submitted && subuserChoice === SubuserChoiceStatus.OptedOut}
          />
        </div>
        <div className="w-full max-md:hidden">
          <GoBackButton onClick={() => setSubuserChoice(SubuserChoiceStatus.Start)} />
        </div>
      </ContentFooter>
    </div>
  );
}
