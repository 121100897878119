import { motion } from "framer-motion";
import React, { ReactElement, Suspense } from "react";
import { useLocation, useOutlet } from "react-router-dom";

import { LoadingSpinner } from "../components/Suspense/Loading";
import LoadingSpinnerPadder from "../components/Suspense/LoadingSpinnerPadder";
import { ENTRY_PATH } from "../constants/constants";
import { useAnimationState } from "../contexts/AnimationStateContext";
import PageTemplate from "./template/PageTemplate";

export default function AnimatedPageOutlet(): ReactElement {
  const { updateIsAnimationComplete } = useAnimationState();
  const location = useLocation();
  const pageContent = useOutlet();

  return (
    <motion.div
      className="flex h-full w-full flex-col items-center"
      onAnimationStart={() => {
        updateIsAnimationComplete(false);
      }}
      onAnimationComplete={() => {
        updateIsAnimationComplete(true);
      }}
      key={location.key}
      initial="hidden"
      animate="visible"
      exit="exit"
      variants={{
        hidden: { opacity: 0 },
        visible: {
          opacity: 1,
          transition: {
            duration: 0.25,
            ease: "easeInOut",
          },
        },
        exit: {
          opacity: 0,
          x: location.pathname === ENTRY_PATH ? 50 : 0,
          transition: {
            duration: 0.25,
            ease: "easeInOut",
          },
        },
      }}
    >
      <Suspense
        fallback={
          <PageTemplate>
            <LoadingSpinnerPadder>
              <LoadingSpinner />
            </LoadingSpinnerPadder>
          </PageTemplate>
        }
      >
        {pageContent}
      </Suspense>
    </motion.div>
  );
}
