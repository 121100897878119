import { SyncEventMessage } from "../models/message";
import { SimpleWebSocket } from ".";

class SyncWebSocket extends SimpleWebSocket {
  public sendMessage(message: SyncEventMessage): void {
    this.send<string>(SyncEventMessage.serialize(message));
  }

  public async *receiveMessage(): AsyncGenerator<string> {
    for await (const msg of this.receive<string>()) {
      yield msg;
    }
  }
}

export default SyncWebSocket;
