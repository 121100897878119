import React, { ReactElement } from "react";

import GlobalErrorScreen from "./GlobalErrorScreen";

export default function NotFoundFallback(): ReactElement {
  // TODO: 2024-09-24 - BAS-1397 - allow placement of go back button here by reworking BackButtonProvider or restructuring app
  return (
    <GlobalErrorScreen
      titleText="Page not found."
      body={
        <div className="text-xl">
          The requested page could not be located. Please try going back to the previous page.
        </div>
      }
    />
  );
}
