import React from "react";

function DashedLine(): React.ReactElement {
  return <div className="mx-2 w-12 border-2 border-dashed border-accent-fill"></div>;
}

export default function ServicesHintDiagram(): React.ReactElement {
  return (
    <div className="flex flex-row items-center justify-center">
      <img
        src="/assets/icons/institution.svg"
        alt="institution logo"
      ></img>
      <DashedLine />
      <img
        src="/assets/icons/basis.svg"
        alt="basis logo"
      ></img>
      <DashedLine />
      <img
        src="/assets/icons/company.svg"
        alt="customer logo"
      ></img>
    </div>
  );
}
