import { debounce } from "lodash";
import React, { Suspense, useCallback, useEffect, useRef, useState } from "react";
import { suspend } from "suspend-react";

import { RequestError } from "../api/apiHandler";
import { getPlatforms } from "../api/connectApi";
import useAsyncError from "../hooks/useAsyncError";
import { Platform } from "../interfaces";
import { getClientConfig } from "../store/clientConfigSlice";
import { useAppSelector } from "../store/hooks";
import UploadSyncButton from "./Buttons/UploadSyncButton";
import { FormTextField } from "./FormTextField";
import PlatformIcon from "./PlatformIcon";

interface PlatformSearchListProps {
  searchText: string;
  onSelect: (p: Platform) => void;
}

function DummyPlatform() {
  return (
    <div className="flex animate-pulse items-center justify-between border-1 border-navy-100 p-4">
      <div className="flex items-center gap-x-4 overflow-hidden">
        <div className="h-12 w-12 rounded-full bg-navy-100" />
        <div className="overflow-hidden">
          <div className="mb-2 h-2 w-72 rounded-full bg-navy-100" />
          <div className="h-2 w-52 rounded-full bg-navy-100" />
        </div>
      </div>
    </div>
  );
}

function PlatformSearchListFallback() {
  return (
    <div className="mt-6 flex w-full flex-col gap-y-2">
      <DummyPlatform />
      <DummyPlatform />
      <DummyPlatform />
    </div>
  );
}

function RefineSearchMessage({ title }: { title: string }): JSX.Element {
  return (
    <div className="mt-2 flex w-full flex-col space-y-2 text-center text-sm text-gray-700 md:mt-6 md:space-y-4">
      <div className="font-semibold">{title}</div>
      <div className="md:hidden">Try searching by URL (e.g. citi.com)</div>
      <div className="max-md:hidden">
        <div>Try searching by URL (e.g. citi.com), or</div>
        <UploadSyncButton>upload your bank statements</UploadSyncButton>
      </div>
    </div>
  );
}

function PlatformButton({ platform, onSelect }: { platform: Platform; onSelect: () => void }) {
  return (
    <button
      key={platform.platformId}
      onClick={() => onSelect()}
      className="flex w-full cursor-pointer items-center justify-start border-1 border-navy-100 p-2 hover:border-accent-fill md:p-4"
    >
      <div className="mr-2 shrink-0 md:mr-4">
        <PlatformIcon icon={platform.icon} />
      </div>
      <div className="min-w-0 grow overflow-x-hidden">
        <div className="flex w-full flex-col items-start justify-center">
          <div className="truncate font-medium">{platform.displayName}</div>
          <div className="truncate text-sm text-gray-500">{platform.homepageUrl}</div>
        </div>
      </div>
    </button>
  );
}

function PlatformSearchList({ searchText, onSelect }: PlatformSearchListProps) {
  const [, setRequestError] = useState<RequestError | null>(null);
  const asyncThrow = useAsyncError();
  const { defaultPlatformSlugs } = useAppSelector(getClientConfig);

  const scrollRef = useRef<HTMLDivElement>(null);
  const [maskStyle, setMaskStyle] = useState<{ maskImage: string }>({ maskImage: "" });

  const handleScroll = useCallback(() => {
    const target = scrollRef.current;
    if (target != null) {
      const fullGradientThreshold = 200;

      const distanceFromTop = target.scrollTop;
      const distanceFromBottom = Math.trunc(target.scrollHeight - target.scrollTop - target.clientHeight);

      const topGradientStrength = Math.min(distanceFromTop / fullGradientThreshold, 1);
      const bottomGradientStrength = Math.min(distanceFromBottom / fullGradientThreshold, 1);

      const topGradientStop = Math.trunc(Math.max(0, 15 * topGradientStrength));
      const bottomGradientStop = Math.trunc(Math.min(100, 100 - 15 * bottomGradientStrength));

      if (distanceFromTop < 1 && distanceFromBottom < 1) {
        setMaskStyle({ maskImage: "" });
      } else {
        setMaskStyle({
          maskImage: `linear-gradient(to bottom, transparent, black ${topGradientStop}%, black ${bottomGradientStop}%, transparent)`,
        });
      }
    }
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const platforms =
    suspend(async () => {
      try {
        const resp = await getPlatforms(searchText || null, searchText === "" ? defaultPlatformSlugs : null);
        return resp.platforms;
      } catch (e) {
        if (e instanceof RequestError) {
          setRequestError(e);
        } else {
          asyncThrow(e);
        }
        return [];
      }
    }, [searchText]) ?? [];

  useEffect(() => handleScroll(), [platforms, handleScroll]);

  return (
    <div className="flex h-full min-h-0 w-full flex-col space-y-2">
      {searchText === "" && <div className="text-xs font-light text-gray-700">Most popular banks</div>}
      {searchText !== "" && platforms.length === 0 && (
        <RefineSearchMessage title={`No banks found matching ${searchText}`} />
      )}
      <div
        className="flex grow flex-col space-y-2 overflow-y-scroll"
        style={maskStyle}
        ref={scrollRef}
        onScroll={handleScroll}
      >
        {platforms.map((platform) => (
          <PlatformButton
            key={platform.platformId}
            platform={platform}
            onSelect={() => onSelect(platform)}
          />
        ))}
      </div>
      <div className="shrink-0">
        {searchText !== "" && platforms.length > 0 && <RefineSearchMessage title="Don't see your bank?" />}
      </div>
    </div>
  );
}

export interface PlatformSearchSelectorProps {
  onSelect: (p: Platform) => void;
  query?: string;
}

export default function PlatformSearchSelector({ onSelect, query = "" }: PlatformSearchSelectorProps) {
  const [debouncedSearchText, _setDebouncedSearchText] = useState<string>(query);
  const updateURLWithoutRerender = (text: string) => {
    const url = new URL(window.location.href);
    text ? url.searchParams.set("search", text) : url.searchParams.delete("search");
    window.history.replaceState({}, "", url.toString());
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearchText = useCallback(
    debounce((text: string) => {
      _setDebouncedSearchText(text);
      updateURLWithoutRerender(text);
    }, 200),
    [],
  );

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    debounceSearchText(e.target.value);
  }

  return (
    <div className="mt-4 flex h-full min-h-0 w-full flex-col items-center space-y-6 overflow-y-auto md:mt-10">
      <div className="w-full shrink-0 pt-4">
        <FormTextField
          fullWidth
          id="search-input"
          name="search-input"
          label="Search by Name or URL"
          defaultValue={query}
          onChange={handleChange}
        />
      </div>
      <div className="w-full grow overflow-y-auto">
        <Suspense fallback={<PlatformSearchListFallback />}>
          <PlatformSearchList
            searchText={debouncedSearchText}
            onSelect={onSelect}
          />
        </Suspense>
      </div>
    </div>
  );
}
