import React, { ReactElement } from "react";

import { defaultErrorMessage } from "../../constants/constants";
import { MajorSyncErrorType, MinorSyncErrorType, SyncError } from "../../models/error";

export interface PromptErrorReasonProps {
  error: SyncError;
}

function getErrorMessage(error: SyncError): string {
  switch (error.majorType) {
    case MajorSyncErrorType.User:
      switch (error.minorType) {
        case MinorSyncErrorType.InvalidCredentials:
          return "Your credentials were incorrect. Please try again.";
        case MinorSyncErrorType.Timeout:
          return "We did not receive a response from you in time. Please try again.";
        case MinorSyncErrorType.InvalidMFA:
          return "The code you entered was incorrect. Please try again.";
        default:
          return defaultErrorMessage;
      }
    case MajorSyncErrorType.Internal:
      switch (error.minorType) {
        case MinorSyncErrorType.Unavailable:
        case MinorSyncErrorType.Unknown:
          return "We experienced an issue connecting to your bank. Please try again.";
        default:
          return defaultErrorMessage;
      }
    default:
      return defaultErrorMessage;
  }
}

export default function PromptErrorMessage({ error }: PromptErrorReasonProps): ReactElement {
  const errorMessage = getErrorMessage(error);

  return (
    <p
      role="alert"
      className="text-xs font-medium text-orange-500"
    >
      {errorMessage}
    </p>
  );
}
