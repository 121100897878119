import { UUID } from "../types";
import BaseModel from "./base";
import { BaseSyncEventPayload } from "./event";

enum MessageType {
  Event = "event",
}

export enum SyncStage {
  Authenticate = "authn",
  CreateSubUser = "create-subuser",
  CheckUserPriv = "user-privilege",
  FetchAccounts = "accounts",
  FetchTransactions = "transactions",
  FetchCompanyIdentity = "identity",
  FetchRelatedEntities = "related-entities",
  FinalizeResult = "finalize",
}

export interface SyncMessage {
  messageId: UUID;
  sessionId: UUID;
  payload: BaseSyncMessagePayload;
}

export interface BaseSyncMessagePayload {
  eventId: UUID;
}

export class SyncEventMessage implements SyncMessage {
  messageType: MessageType.Event = MessageType.Event;
  messageId: UUID;
  sessionId: UUID;
  payload: BaseSyncEventPayload;

  constructor(props: { messageId: UUID; sessionId: UUID; payload: BaseSyncEventPayload }) {
    const { messageId, sessionId, payload } = props;
    const { eventId, eventType } = payload;
    if (messageId == null || sessionId == null || payload == null) {
      throw new Error("Missing required SyncEventMessageField");
    }

    if (eventType == null || eventId == null) {
      throw new Error("Missing required BaseSyncEventPayload field");
    }

    this.messageId = messageId;
    this.sessionId = sessionId;
    this.payload = payload;
  }

  static deserialize(message: string): SyncEventMessage {
    return new SyncEventMessage(BaseModel.deserialize<SyncEventMessage>(message));
  }

  static serialize(message: SyncEventMessage): string {
    return BaseModel.serialize(message as unknown as BaseModel);
  }
}
