import { XMarkIcon } from "@heroicons/react/24/outline";
import { motion } from "framer-motion";
import { ReactElement } from "react";
import React from "react";

import { useCopy } from "../hooks/useCopy";
import ContentBody from "../pages/template/ContentBody";
import AboutBasis from "./AboutBasis";
import BasisLogo from "./BasisLogo";
import ServiceInfo from "./ServiceInfo";
import ValueProposition from "./ValueProposition";

interface SlideUpProps {
  isOpen: boolean;
  onClose: () => void;
}

const slideUpVariants = {
  hidden: { y: "100%", opacity: 0 },
  visible: { y: "0%", opacity: 1, transition: { duration: 0.25 } },
  exit: { y: "100%", opacity: 0, transition: { duration: 0.25 } },
};

function SlideUpContent({ onClose }: { onClose: () => void }): ReactElement {
  const { landServiceInfo, slideupSubtitle } = useCopy();

  return (
    <div className="flex h-full w-full flex-col">
      <div
        className="flex h-12 w-full flex-row items-center justify-end px-6"
        onClick={onClose}
      >
        <XMarkIcon className="h-6 w-6 stroke-neutral-900" />
      </div>
      <div className="mx-6">
        <ContentBody>
          <ValueProposition
            landSubtitle={slideupSubtitle}
            landBody={""}
          />
          <div className="mb-2 mt-4">
            <ServiceInfo content={landServiceInfo} />
          </div>
        </ContentBody>
        <div className="flex flex-row justify-between border-t-1 border-neutral-900 pb-6 pt-6">
          <BasisLogo size="lg" />
          <AboutBasis alignment="right" />
        </div>
      </div>
    </div>
  );
}

export default function MobileInformationDrawer({ isOpen, onClose }: SlideUpProps): ReactElement | null {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      ></div>
      <motion.div
        className="absolute bottom-0 z-10 h-fit max-h-[80%] w-full bg-accent-fill-muted"
        variants={slideUpVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <SlideUpContent onClose={onClose} />
      </motion.div>
    </div>
  );
}
