import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { SubuserStrategy } from "../components/OngoingConnectivity/OngoingConnectivityCommon";
import { SyncSessionParams } from "../interfaces";
import { SyncError } from "../models/error";
import { RootState } from ".";

const initialState: SyncSessionParams = {
  sessionId: "",
  syncTargetId: "",
  platformId: "",
  platformConfig: {
    platformId: "",
    displayName: "",
    platformType: "",
    homepageUrl: "",
    logo: "",
    icon: "",
    aggregator: null,
    credentialPrompts: [],
    subuserStrategy: SubuserStrategy.Unspecified,
    aggregatorUsername: null,
    aggregatorUrl: null,
  },
  error: null,
  terminalError: null,
};

export const syncSessionSlice = createSlice({
  name: "sync",
  initialState,
  reducers: {
    createSyncSession: (state, action: PayloadAction<SyncSessionParams>) => {
      state = action.payload;
      return state;
    },
    setError: (state, action: PayloadAction<SyncError | null>) => {
      state.error = action.payload;
    },
    setTerminalError: (state, action: PayloadAction<SyncError | null>) => {
      state.terminalError = action.payload;
    },
  },
});

export const { createSyncSession, setError, setTerminalError } = syncSessionSlice.actions;

export const getCurrentSyncSession = (state: RootState): SyncSessionParams => {
  return state.sync;
};

export const getError = (state: RootState): SyncError | null => {
  return state.sync.error;
};

export const getTerminalError = (state: RootState): SyncError | null => {
  return state.sync.terminalError;
};

export default syncSessionSlice.reducer;
